import type { QueryParams } from '~/types/api'
import type {
  Currency,
  CustomerGroup,
  CustomerGroupPagedResult,
} from '~/types/api'

export function createCustomerGroupsRepository() {
  const { $api } = useNuxtApp()
  const { get } = baseRepository($api)

  const DOMAIN = 'customer-groups'
  const DOMAIN_CURRENCY = 'currency'

  async function getAll(params?: QueryParams) {
    return get<CustomerGroupPagedResult>([DOMAIN], { params })
  }

  async function getByNid(nid: number) {
    return get<CustomerGroup>([DOMAIN, nid.toString()])
  }

  async function getCurrencyForCustomerGroup(customerGroupId: number) {
    return get<Currency>([DOMAIN, customerGroupId.toString(), DOMAIN_CURRENCY])
  }

  return {
    getAll,
    getByNid,
    getCurrencyForCustomerGroup,
  }
}
